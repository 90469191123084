@import 'styles/variables';

.transactionLead {
  margin-bottom: 12px;
}

.transactions {
  padding-top: 20px;

  &:first-of-type {
    padding-top: 0;
  }
}

.transactionLabel {
  font-size: 14px;
  line-height: 17px;
  background: #f9ecdc;
  color: #181818;
  padding: 5px 10px;
  display: inline-block;
  margin-bottom: 4px;
  border-radius: 5px;
}

.transactionLine {
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
  border-bottom: 1px solid #f1f1f1;

  &:last-of-type {
    border: none;
  }
}

.transactionText {
  font-family: var(--typo-sansserif-fontfamily);
  font-weight: var(--typo-light-fontweight);
  line-height: 22px;
  margin-bottom: 7px;
  span {
    text-transform: capitalize;
  }
}

.transactionSubText {
  font-size: 14px;
  line-height: 17px;
  color: #969696;
}

.transactionRight {
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.transactionAmount {
  line-height: 20px;
  margin-bottom: 8px;
}

.ghostBottomPad {
  width: 100%;
  margin-top: 10px;
}

@media only screen and (min-width: $device-m) {
  .section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 70px;
  }
}
