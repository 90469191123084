@import 'styles/variables';
@import 'styles/mixins';

.container {
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  max-width: 1440px;
  margin: 0 auto;
  z-index: 2;
  position: relative;
  align-items: center;
}

.brandmarkLink {
  width: 134px;
  height: 32px;
  height: fit-content;
  display: none;
}

.arrowContainer {
  width: 100%;
  button {
    display: flex;
  }
}

.close {
  justify-self: end;
}

@media only screen and (min-width: $device-m) {
  .container {
    padding: 43px 50px 43px;
  }

  .arrowContainer {
    max-width: 886px;
  }
}

@media (min-width: $device-hi-res) {
  .container {
    display: grid;
    grid-template-columns: 1fr 8fr 1fr;
    grid-column-gap: 93px;
  }
  .brandmarkLink {
    display: block;
  }
}
