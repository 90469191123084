@import 'styles/variables';

.container {
  max-width: 887px;
  margin: 0 auto;
  padding: 20px;
}

.body {
  max-width: 546px;
}

.switchSelector {
  margin: 10px 0 20px;
}

@media only screen and (min-width: $grid-tablet) {
  .container {
    padding: 0 40px;
  }
}
@media only screen and (min-width: $device-m) {
  .container {
    margin: 0 auto;
  }
}
@media only screen and (min-width: $device-hi-res) {
  .container {
    padding: 20px 0;
  }
}
