@import 'styles/variables';

.copyInvite {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.copyInvite__url {
  width: 100%;

  input {
    font-size: 24px;
  }
}

:global(.copy-link-button--copy-invite-link) {
  min-width: 335px;
}

@media only screen and (min-width: 500px) {
  .copyInvite {
    flex-direction: row;
    align-items: flex-end;
  }
  :global(.copy-link-button--copy-invite-link) {
    min-width: unset;
  }
}
