@import 'styles/variables';
@import 'styles/mixins';

.container {
  @include flow-container;
  max-width: 887px;
  margin: 0;
  min-height: calc(100vh - #{$header-height});
}

.titleContainer {
  h1 {
    margin-bottom: 0;
  }
}

.cta {
  margin-top: -30px;
}
@media only screen and (min-width: $grid-tablet) {
  .container {
    padding: 0 40px;
    min-height: unset;
  }

  .titleContainer {
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 30px;
  }
}
@media only screen and (min-width: $device-m) {
  .container {
    margin: 0 auto;
  }
}
@media only screen and (min-width: $device-hi-res) {
  .container {
    padding: 20px 0;
  }
}
