@import 'styles/variables';

.container {
  width: 100%;
  margin: 40px 0;
}

.fieldset[disabled] {
  opacity: 0.3;
}

.section {
  padding: 20px 0;
  &:first-of-type {
    padding-top: 10px;
  }
  &:nth-of-type(2) {
    padding-bottom: 0;
    margin-bottom: 36px;
  }
}

.sectionHeader {
  margin-bottom: 30px;
}

.sectionTitle {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 20px;
}

.cardErrors {
  color: $error-red;
}

.btnContainer {
  margin-top: 40px;
}

@media only screen and (min-width: 750px) {
  .container {
    margin-bottom: 80px;
  }

  .section {
    display: grid;
    grid-template-columns: 4fr 5fr;
    grid-column-gap: 100px;
    padding-top: 70px;
    padding-bottom: 40px;
    border-bottom: 1px solid #e7e7e7;
    &:nth-of-type(2) {
      padding-bottom: 40px;
      margin-bottom: 0;
    }
  }
}
