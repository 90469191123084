@import "styles/variables";

.copy-link-button {
  min-width: 73px;

  p {
    font-size: 14px;
    line-height: 21px;
  }
}

