@import 'styles/variables';

.container {
  flex: 1 1;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  justify-content: end;
  padding-bottom: 40px;
}

.textContainer {
  max-width: 330px;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.bankImage {
  margin-bottom: 20px;
}

.trigger {
  font-size: 14px;
  line-height: 17px;
  font-family: var(--typo-sansserif-fontfamily);
  font-weight: var(--typo-light-fontweight);
  text-decoration: underline;
  letter-spacing: inherit;
}

.text {
  font-size: 14px;
  line-height: 17px;
  margin: 0 auto 20px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.modalBtn {
  position: fixed;
  bottom: 20px;
  background: white;
  right: 20px;
  left: 20px;
  max-width: 335px;
}

@media only screen and (min-width: 400px) {
  .modalBtn {
    position: initial;
  }
}

@media only screen and (min-width: $grid-tablet) {
  .container {
    gap: 40px;
    padding: 40px 0;
  }
}
