@import '@collegebacker/backer-ui/tokens/grids';

//////////////////////////////
// LAYOUT ////////////////////
//////////////////////////////
$content-max-width-new: 886px;
$flow-max-width: 433px;
$content-max-width: 992px;
$header-height-mobile-nav-bar: 60px;
$header-height: 54px;
$header-height-desktop: 110px;
$layout-top-bar-height: 116px;
$dashboard-max-width: 942px;
$fund-summary-max-width: 822px;
$profile-max-width: 745px;
$onboarding-max-width: 868px;

//////////////////////////////
// DEVICE MIN-WIDTHS /////////
//////////////////////////////

// When using these variables as a max-width, make sure to subtract 1
$device-iphone-x: 375px;
$device-xs: 481px; // portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide
$device-s: 641px; // portrait tablets, landscape e-readers, landscape 800x480 or 854x480 phones
$device-m: 961px; // tablet, landscape iPad, lo-res laptops ands desktops
$device-l: 1025px; // big landscape tablets, laptops, and desktops
$device-hi-res: 1281px; // hi-res laptops and desktops

//////////////////////////////
// NEW BREAKPOINTS ///////////
//////////////////////////////

// Mobile (default) 375 - 499

// Tablet 500 - 1023
$tablet: 500px;
// Desktop 1024 - 1439
$desktop: 1024px;
// Large desktop 1440+
$desktop-lg: 1440px;

$modal-desktop: 621px;

// NEWEST GRID BREAKPOINTS
$grid-tablet: 621px;
$grid-desktop: 1025px;
// custom breakpoints

$button-full-width: 424px;

//////////////////////////////
// COLORS ////////////////////
//////////////////////////////

$white: #ffffff;
$pale-grey-1: #f7f9fa;
$pale-grey-2: #f6f5fa;
$pale-grey-3: #edeff0;
$pale-grey-4: #f1f4f6;
$silver-1: #cfd0d1;
$silver-2: #babfc2;
$silver-3: #e7e8e9;
$silver-4: #ccdce1;
$cool-grey-1: #9ea1a3;
$cool-grey-2: #879799;
$slate-grey-1: #5d5a6b;
$slate-grey-2: #5e6a70;
$grey-btn-link: rgba(94, 106, 112, 0.5);
$border-grey: rgba(13, 21, 26, 0.04);
$light-grey-blue: #afbabf;
$blue-grey: #7698c4;

$duck-egg-blue: #dee0e0;
$very-light-blue-40: rgba(227, 231, 236, 0.4);
$water-blue: #17a1e5;
$water-blue-2: #1596d6;
$turquoise-blue: #00bbcc;
$teal-blue: #0095a9;
$ocean: #007188;
$deep-teal: #004c66;
$light-navy-1: #144b66;
$light-navy-2: #12375c;
$squash: #f2a919;

$input-text: #131414;
$error-red: #bd0f0f;
$section-border: rgba(24, 61, 97, 0.06);

// new for v3
$light-navy-blue: #315481;
$metallic-blue: #557391;
$pale-blue: #d5e6fc;
$dark-sky-blue: #5596e7;
$windows-blue: #3376cc;
$pale-grey: #f2f7fa;
$pale-grey-two: #f6f9fe;
$bluey-grey: #8599ad;
$french-blue: #4c79b3;
$light-blue-grey-two: #b8cae0;
$cloudy-blue: #a8bfd6;
$slate-grey: #6b6e71;
$cool-grey: #9ea1a3;
$midnight-2: #454d52;
$light-grey-blue-two: #86addf;
$light-grey-background: #f6f7f8;
$soft-blue: #73a5e6;
$greyish-blue: #5f7a9e;
$pale-peach: #ffedb5;
$orange: #e68b0d;
$twilight-blue: #0e448b;
$dark-slate-blue: #142f52;

// new for Backer

$midnight: #13204f;
$backer-blue: #43579d;
$ivory: #fbecda;
$white: #ffffff;
$brick: #e88c6c;
$rose: #f4bda2;
$sage: #679c9f;
$sunshine: #f2c368;
$sky: #7c9ed7;
$paper: #f7f7f1;
$offwhite: #f2f2f2;
$fine-print: rgba(19, 32, 79, 0.5);
$dark-gray: #606060;
$light-purple: #91b1e8;
$secondary-content: #636b82;
$tertiary-content: #aeb2bd;
$tertiary-bg: #f9ecdc;
$lightest-purple: #e9ecf6;
$primary-highlight: #e1e6f5;

// new for brand refresh
$almost-black: #141414;
$text-light: #969696;
$fill-accent: #b988d8;
$fill-light: #e7e7e7;
$fill-accent-light: #f9ecdc;

//////////////////////////////
// Z-INDEXES /////////////////
//////////////////////////////

$sticky-nav: 3;
