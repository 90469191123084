@import 'styles/variables';

$header-height: 37px;

.container {
  padding: 30px 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 885px;
  margin: 0 auto;
  flex: 1;
  &.managePaymentMethods {
    padding-left: 0;
    padding-right: 0;
  }
}
.switch {
  width: 100%;
  max-width: 480px;
}
.desktop {
  display: none;
  margin-left: 4px;
}

.btn {
  flex: 1;
}

.body {
  width: 100%;
  max-width: 434px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

@media only screen and (min-width: 460px) {
  .desktop {
    display: block;
  }
}

@media only screen and (min-width: 750px) {
  .body {
    max-width: unset;
    padding-top: 30px;
  }
}

@media only screen and (min-width: 950px) {
  .container {
    padding: 40px 0 20px;
  }
}
