@import 'styles/variables';

.input-new__container {
  width: 100%;
  margin: 0 0 20px 0;
  &--less-margin {
    margin-bottom: 10px;
  }
  &.disabled {
    cursor: unset;
    opacity: 0.25;
  }
  &.error {
    .input-new {
      border-bottom-color: $error-red;
    }
    .input-new__label {
      color: $error-red;
    }
  }
}
.input-new__inner-container {
  display: inline-flex;
  width: inherit;
  border-bottom: 1px solid #1a1a1a;
  position: relative;
  &.disabled {
    opacity: 0.25;
  }
  &.error {
    border-bottom-color: $error-red;
  }
}

.input-money-new {
  border-bottom: 1px solid #1a1a1a;
}
.input-new {
  height: 67px;
  font-family: var(--typo-sansserif-fontfamily);
  font-weight: var(--typo-light-fontweight);
  top: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: text;
  font-size: 22px;
  width: inherit;
}

.input-new--white {
  background: #fff;
}

.input-new__label-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  color: #1a1a1a;
  // padding: 0 16px;
}

.input-new__placeholder {
  color: #969696;
  margin: 0;
  font-size: 14px;
  line-height: 24px;
  line-height: inherit;
  &.disabled {
    color: $almost-black;
  }
}

.input-new__input {
  border: none;
  width: 100%;
  padding: 0 0 12px;
  font-family: var(--typo-sansserif-fontfamily);
  font-weight: var(--typo-light-fontweight);
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.input-new__input-new[disabled] {
  -webkit-text-fill-color: $midnight;
  color: $midnight;
}

input-new[type='date']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}
input-new[type='date']::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  display: none;
}

.input-new__helper-text {
  font-size: 14px;
  line-height: 24px;
  color: #969696;
  margin-top: 4px;
}
