@import 'styles/variables';

.input-textarea {
  height: unset;
  font-size: 17px;
  padding-top: 16px;
  border-bottom: 1px solid #1a1a1a;
}
.input-textarea__input::placeholder {
  font-size: 17px !important;
}
.input-textarea--border-none {
  border-bottom: none;
}
.input-textarea--white {
  background: #fff;
}
.input-textarea__input {
  resize: none;
  border-radius: inherit;
  background: inherit;
}
.input-textarea__reset-btn {
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.3px;
  text-decoration-line: underline;
  opacity: 0.7;
  margin: 0 16px 16px;
  width: fit-content;
  align-self: flex-end;
}
.input-new__label {
  padding-bottom: 20px;
}
