@import 'styles/variables';
.screen__section-title {
  letter-spacing: 0.3px;
  margin-top: 0;
  font-size: 17px;
  line-height: 21px;
}
.screen__section-title--no-subtitle {
  margin-bottom: 12px;
}

.screen__section-title--confirmation-subheader {
  font-family: var(--typo-sansserif-fontfamily);
  font-weight: var(--typo-medium-fontweight);
  font-size: 20px;
  text-align: left;
}

.screen__section-subtitle {
  margin-bottom: 12px;
  letter-spacing: 0.1px;
  line-height: 24px;
  margin-top: 8px;
  font-family: var(--typo-sansserif-fontfamily);
  font-weight: var(--typo-light-fontweight);
}

@media only screen and (min-width: $device-s) {
  .screen__section-title--payment-method {
    font-size: 20px;
  }
}

@media only screen and (min-width: $device-m) {
  .screen__section-title--confirmation-subheader {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 48px;
  }
  .screen__section-title--account-fee {
    margin-bottom: 18px;
  }
}

@media only screen and (min-width: $device-hi-res) {
  .screen__section-title--account-fee {
    font-size: 20px;
    line-height: 28px;
  }
}
