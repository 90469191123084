@import 'styles/variables';

.back-link {
  display: flex;
  align-items: baseline;
  color: $cool-grey-1;
  cursor: pointer;
  padding: 20px;
  transition: color 0.2s ease-in-out;
}
.back-link--no-padding {
  padding: 0;
}
.back-link--button {
  display: flex;
  align-items: baseline;
  cursor: pointer;
  padding: 4px 10px 3px 6px;
}
.back-link--button:hover,
.back-link--button:focus {
  opacity: 0.9;
  color: $backer-blue;
}
.back-link--button-no-icon {
  padding-left: 10px;
}
.icon__left-caret {
  height: 24px;
  width: 24px;
  margin-right: 2px;
  position: relative;
  top: 1px;
}
.icon-close {
  height: 14px;
  width: 14px;
}
.questionMark {
  width: 24px;
  height: 24px;
  grid-column: 12 / span 1;
  justify-self: end;
}
