@import 'styles/variables';

.container {
  padding-bottom: 20px;
}

.gradientContainer {
  position: relative;
}
.gradient {
  composes: gradient from 'modules/edit-contributions/EditContributions.module.scss';
}

.noPaymentMethodImg{
  padding-bottom: 20px;
}
.infoMessage {
  margin: 6px 0 30px;
}
@media only screen and (min-width: $grid-tablet) {
  .container {
    padding-bottom: 100px;
  }
}
