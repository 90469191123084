@import 'styles/variables';

.dividerGiftRow {
  margin: 20px 0;
}

.showAllBtn {
  width: 100%;
  margin-top: 40px;
}

.giftRow {
  display: flex;
  flex-direction: column;
  position: relative;
}

.fundPhoto {
  flex: 0 0 auto;
  --size: 70px;
  margin-right: 20px;
  margin-bottom: 15px;
}

.frequencyAmount {
  padding-bottom: 5px;
  max-width: 360px;
}

.frequencyDate {
  max-width: 360px;
}

.paymentDateCaption {
  color: var(--color-main-200);
  margin-top: 15px;
}

.paymentDateCaptionFailed {
  color: var(--color-service-error-main);
}

.editBtn {
  position: absolute !important;
  right: 0;
}

.giftsImg {
  padding-bottom: 30px;
}

.showSupport {
  max-width: 440px;
  padding-bottom: 20px;
}

.setupGift {
  position: relative;
}

.gradient {
  position: absolute;
  width: 309.83px;
  height: 546.77px;
  left: -23.19px;
  top: -145.41px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #fdcebe 0%,
    rgba(253, 206, 190, 0) 100%
  );
  opacity: 0.7;
  transform: rotate(-120deg);
  z-index: -1;
}

.cta {
  width: 233px;
  height: 40px;
  border-radius: 80px;
}

@media only screen and (min-width: $device-m) {
  .giftRow {
    flex-direction: row;
  }
  .fundPhoto {
    margin-bottom: 0px;
  }

  .setupGift {
    margin-left: 100px;

  }
  .gradient {
    position: absolute;
    width: 255.09px;
    height: 516.39px;
    left: 87px;
    top: -175.71px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #fdcebe 0%,
      rgba(253, 206, 190, 0) 100%
    );
    opacity: 0.7;
    transform: rotate(-105deg);
    z-index: -1;
  }
}
