@import 'styles/mixins';

.container {
  @include flow-container;
  overflow-x: hidden;
  max-width: 600px;
}

.screenHeaderContainer {
  padding-bottom: 40px;
  max-width: 546px;
}

.verifyButton {
  margin-top: 60px;
}

@media only screen and (min-width: $device-m) {
  .container {
    max-width: $onboarding-max-width;
    padding: 20px 0px 80px;
  }
}
