@import 'styles/variables';

.container {
  padding: 20px 20px 30px;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  justify-content: center;
  max-width: 885px;
  margin: 0 auto;
  width: 100%;
}

.body {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 434px;
}

.header {
  display: none;
}

.title {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 20px;
}

.text {
  max-width: 260px;
}

.dateLabel {
  font-size: 14px;
  line-height: 17px;
  color: #969696;
  margin-top: 20px;
}
.polaroidContainer {
  position: relative;
  transform: rotate(4.86deg);
  filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.07));
  margin-left: 10px;
  position: relative;
  top: -8px;
}

.frame {
  width: 100px;
  z-index: 2;
  position: relative;
}

.polaroidImage {
  position: absolute;
  top: 8px;
  left: 6px;
  width: 85px;
  background: white;
}

@media only screen and (min-width: 750px) {
  .container {
    display: grid;
    grid-template-columns: 4fr 5fr;
    grid-column-gap: 100px;
  }

  .header {
    display: block;
  }
}

@media only screen and (min-width: 950px) {
  .container {
    padding: 20px 0 70px;
  }
}
