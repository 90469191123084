.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.optionsDropdown {
  width: 100%;
  z-index: 1;

  display: flex;
  flex-direction: column;

  background: var(--color-peach-50);
  border-radius: var(--radius-l);

  overflow: hidden;

  // props to hide dropdown
  opacity: 0;
  max-height: 0;

  ul {
    display: flex;
    flex-direction: column;
  }

  li {
    position: relative;

    &::after {
      position: absolute;
      bottom: 0;
      left: 20px;
      content: '';
      display: block;
      width: calc(100% - 40px);
      height: 1px;
      background: rgba(0, 0, 0, 0.1);
      transform: translateY(1px);
    }

    &:last-child::after {
      display: none;
    }

    &:hover {
      .item {
        background: var(--color-peach-200);
      }

      &::after {
        background: transparent;
      }
    }

    &:focus-within {
      .item {
        background: var(--color-peach-200);
        outline: 2px solid var(--color-peach-500);
      }
    }
  }
}

.caption {
  display: block;
  padding: 20px 20px 10px 20px;
  opacity: 0.5;
}

.item {
  position: relative;
  display: flex;
  // align-items: center;
  gap: 12px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  padding: 15px 20px;
  // background: var(--color-peach-50);
}

.markerIcon {
  flex-shrink: 0;
  fill: var(--color-main-500);
  opacity: 0.3;
  margin-top: 3px;
}

.dropdownVisible {
  opacity: 1;
  max-height: 500px;
  transition: opacity 0.2s ease-in-out, max-height 0.3s ease-in-out;
}
