@import 'styles/variables';

.container {
  display: flex;
  justify-content: center;
  max-width: 885px;
  margin: 0 auto;
  width: 100%;
}

.body {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 480px;
}

.header {
  display: none;
}

.titleContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 5px;
}
.title {
  font-size: 24px;
  line-height: 30px;
}

.text {
  max-width: 260px;
}

.dateLabel {
  font-size: 14px;
  line-height: 17px;
  color: #969696;
  margin-top: 20px;
}
.gradientCircle {
  display: flex;
  width: 80px;
  height: 80px;
  padding: 20px 7.579px 16px 7.579px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background: linear-gradient(257deg, #f6f0f9 6.93%, rgba(246, 240, 249, 0) 40%),
    linear-gradient(
      257deg,
      rgba(246, 240, 249, 0) 55.33%,
      rgba(246, 240, 249, 0.8) 80.28%
    ),
    linear-gradient(116deg, #ffd7d0 27.79%, rgba(250, 219, 213, 0) 95.39%),
    var(--accent-50, #f6f0f9);
  p {
    text-align: center;
  }
}
@media only screen and (min-width: 750px) {
  .container {
    display: grid;
    grid-template-columns: 4fr 5fr;
    grid-column-gap: 100px;
  }

  .header {
    display: block;
  }
}

@media only screen and (min-width: 950px) {
  .container {
    padding: 20px 0 70px;
  }
}
