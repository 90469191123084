.wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.stripeInput {
  cursor: text;
  padding: 20px 0;
  box-shadow: 0 1px 0 0 var(--color-main-500);

  transition: box-shadow 0.2s ease-in-out;

  &:hover,
  &:focus-within {
    box-shadow: 0 2px 0 0 var(--color-main-500);
  }
}

.focused {
  box-shadow: 0 2px 0 0 var(--color-main-500);
}

.focusedError {
  box-shadow: 0 2px 0 0 var(--color-service-error-main) !important;
}

.error {
  box-shadow: 0 1px 0 0 var(--color-service-error-main);

  &:hover,
  &:focus-within {
    box-shadow: 0 2px 0 0 var(--color-service-error-main);
  }
}

.errorText {
  color: var(--color-service-error-main);
}
