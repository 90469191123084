@import "styles/variables";

.container {
  padding: 20px;
  max-width: $content-max-width-new;
  margin: 0 auto;
}

@media only screen and (min-width: $device-xs) {
  .container {
    padding: 20px 0;
  }
}
