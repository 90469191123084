@import 'styles/variables';

.back-button-header {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  margin: 0 auto 22px;
}
.back-button-header--earn {
  z-index: 4;
  position: relative;
  margin-top: 8px;
}
.back-button-header__img {
  width: 24px;
}
.back-button-header__title {
  text-align: center;
  font-family: var(--typo-sansserif-fontfamily);
  font-weight: var(--typo-medium-fontweight);
  color: $midnight;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.5px;
}
.back-button-header__button {
  padding: 0 12px;
  justify-self: baseline;
}
.back-button-header__button--earn {
  padding-left: 22px;
}
