// TAB PAGE
.page {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: inherit;
  height: inherit;
  flex: 1;
}
