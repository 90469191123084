@import 'styles/mixins';

.container {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.text {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 20px;
}

.textBottom {
  composes: text;
  margin-bottom: 80px;
}
