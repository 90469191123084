@import 'styles/variables';
.money__dollar-sign--superscript,
.money__cents--superscript {
  font-size: 0.52em;
  vertical-align: super;
  margin-right: 1px;
  top: 3px;
  position: relative;
}
.money--pending {
  color: $squash;
}
