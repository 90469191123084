@import 'styles/variables';

.content {
  max-width: 438px;
}

.cards {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.card {
  border-radius: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border: 1px solid var(--main-100, #b9b9b9);
  width: 100%;
}

.annualCardContainer {
  position: relative;
  width: 100%;
}

.labelTag {
  position: absolute;
  top: -12px;
  left: 20px;
}
.cardAnnual {
  border-color: transparent;
  background: linear-gradient(257deg, #f6f0f9 6.93%, rgba(246, 240, 249, 0) 40%),
    linear-gradient(
      257deg,
      rgba(246, 240, 249, 0) 55.33%,
      rgba(246, 240, 249, 0.8) 80.28%
    ),
    linear-gradient(116deg, #ffd7d0 27.79%, rgba(250, 219, 213, 0) 95.39%),
    var(--accent-50, #f6f0f9);
}
.strikethrough {
  color: var(--main-200, #8a8a8a);
  text-decoration-line: line-through;
}
.highlight {
  color: var(--accent-800, #9a63c3);
}

.amountGap {
  display: flex;
  gap: 5px;
  align-items: baseline;
}
.cardSelected {
  composes: card;
  border-color: var(--accent-800, #9a63c3);
}

.cardLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  h2 {
    margin: 0;
  }
}

.cardRight {
  display: flex;
  gap: 10px;
}

.features {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 20px 0;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
}
.right {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
}

.listIcons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.bottomContainer {
  max-width: 335px;
}
.ctaHelperText {
  text-align: center;
  margin-bottom: 10px;
}
.ctaContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.buttonGhost {
  span {
    color: var(--main-100, #b9b9b9) !important;
  }
}

.lightLabel {
  color: var(--main-transparent-200, rgba(20, 20, 20, 0.5));
}

.row {
  display: flex;
  gap: 10px;
  align-items: center;
}

@media only screen and (min-width: $device-s) {
  .features {
    padding: 20px 0;
  }
}
@media only screen and (min-width: $device-m) {
  .bottomContainer {
    max-width: unset;
  }

  .ctaHelperText {
    text-align: left;
  }
}
