@import 'styles/variables';

:global(.modal-area--contribution-confirmation) {
  padding-left: 0;
  padding-right: 0;
}

.container {
  max-width: 480px;
  margin: 0 auto;
}

.header {
  padding: 0 20px 40px;
}
.summarySection {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px 20px;
  gap: 20px;
}

.image {
  --size: 100px;
  flex: 0 0 auto;
}

.title {
  font-size: 24px;
  line-height: 30px;
  font-family: var(--typo-sansserif-fontfamily);
  font-weight: var(--typo-medium-fontweight);
  margin-bottom: 15px;
}

.flexContainer {
  display: flex;
  margin-bottom: 60px;
}

.transactionImg {
  margin-right: 20px;
}
.section {
  padding: 35px 20px 20px;
}

.subtitle {
  font-family: var(--typo-sansserif-fontfamily);
  font-weight: var(--typo-light-fontweight);
  font-size: 17px;
  line-height: 27px;
}

.buttonFullWidth {
  width: 100%;
}

.dateLabel {
  padding-top: 15px;
  color: #8a8a8a;
  font-size: 14px;
}

.email {
  font-weight: var(--typo-medium-fontweight);
  color: var(--color-accent-500);
}

@media only screen and (min-width: 500px) {
  .header,
  .summarySection,
  .section {
    padding-left: 0;
    padding-right: 0;
  }

  .flexContainer {
    margin-bottom: 35px;
  }
}
