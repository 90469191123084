@import './variables';

@mixin placeholder {
  color: #949494;
}

$padding: 20px;

@mixin flow-container {
  padding: $padding;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  max-width: $flow-max-width + ($padding * 2);
  margin: 0 auto;
}
