@import 'styles/variables';

.container {
  flex: 1;
  justify-content: space-between;
}

.card {
  border-radius: 10px;
  background: var(--peach-50, #fcf5ed);
  padding: 15px 20px;
  display: flex;
  max-width: 438px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.lightLabel {
  color: var(--main-transparent-200, rgba(20, 20, 20, 0.5));
}

.highlight {
  color: var(--main-500, #007aff);
}

.stars {
  width: 30px;
  height: 30px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  li {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}

.cardGradient {
  display: flex;
  flex-direction: column;
  max-width: 438px;
  gap: 10px;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  background: linear-gradient(257deg, #f6f0f9 6.93%, rgba(246, 240, 249, 0) 40%),
    linear-gradient(
      257deg,
      rgba(246, 240, 249, 0) 55.33%,
      rgba(246, 240, 249, 0.8) 80.28%
    ),
    linear-gradient(116deg, #ffd7d0 27.79%, rgba(250, 219, 213, 0) 95.39%),
    var(--accent-50, #f6f0f9);
}

.ctaContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  button {
    width: 100%;
  }
}

@media only screen and (min-width: $modal-desktop) {
  .ctaContainer {
    gap: 30px;
  }
}
