@import 'styles/variables';

.input-shareable-url {
  height: 69px;
  font-size: 17px;
  border-bottom: 1px solid #1a1a1a;
}
.fake-input-text {
  padding: 0 0 12px 0;
  display: inline-flex;
  align-items: center;
}
.input-shareable-url__input {
  width: 100%;
  &.editing {
    border-bottom: solid 1px #e1e6e8;
  }
}
.input-shareable-url__handle {
  font-family: var(--typo-sansserif-fontfamily);
  font-weight: var(--typo-medium-fontweight);
}
.input-shareable-url__helper {
  color: $turquoise-blue;
  position: relative;
  height: 42px;
  top: 8px;
  font-size: 14px;
  &.error {
    color: $error-red;
  }
}
