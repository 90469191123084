@import 'styles/variables';

.screen-header {
  width: 100%;
  max-width: 500px;
  &--full-width {
    max-width: unset;
  }
  &--edit-profile {
    max-width: 608px;
  }
  &--share-fund {
    max-width: 660px;
  }
  &--adaptive {
    max-width: 700px;
  }
}
.screen__title {
  font-family: var(--typo-serif-fontfamily);
  font-size: 40px;
  line-height: 47px;
  margin-bottom: 20px;
  &--no-subtitle {
    margin-bottom: 20px;
  }
  &--small {
    font-size: 24px;
    line-height: 130%;
    font-family: var(--typo-sansserif-fontfamily);
    font-weight: var(--typo-medium-fontweight);
  }
  &--invite-backers {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  &--new-payment-method {
    margin-bottom: 10px;
  }
  &--adaptive {
    font-family: var(--typo-sansserif-fontfamily);
    font-weight: var(--typo-medium-fontweight);
    font-size: 24px;
    line-height: 130%;
  }
}

.screen__title--white {
  color: $white;
}
.screen__title--create-contribution {
  margin-bottom: 18px;
}
.screen__title--center {
  text-align: center;
}
.screen__subtitle {
  font-family: var(--typo-sansserif-fontfamily);
  font-weight: var(--typo-light-fontweight);
  font-size: 24px;
  line-height: 130%;
  margin-bottom: 20px;
  display: inline-block;
  &.small {
    font-size: 17px;
    line-height: 22px;
  }
  &--ssn {
    margin-bottom: 40px;
  }
  &--amount-new {
    width: 100%;
    span.skeleton-animation {
      width: inherit;
      display: inline-block;
    }
  }
  &--adaptive {
    font-size: 17px;
    line-height: 160%;
  }
}

@media only screen and (min-width: $device-m) {
  .screen__title--wide {
    width: max-content;
    max-width: unset;
  }
}
@media only screen and (min-width: $device-hi-res) {
  .screen__title--adaptive {
    font-size: 34px;
    line-height: 120%;
  }
  .screen__subtitle--adaptive {
    font-size: 24px;
    line-height: 130%;
  }
}
