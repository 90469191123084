@import 'styles/variables';

.info-bubble-new__button {
  display: inline-block;
  padding: 4px 4px 4px 2px;
  position: relative;
  // top: 2px; @TODO - check to see if this is needed
}

.inline-text-with-info {
  line-height: 1.5;
  color: #557391;
  letter-spacing: 0px;
  font-size: 16px;
}

.tippy-content {
  a {
    text-decoration: underline;
  }
}

.tippy-box {
  border-radius: 10px;
  font-size: 14px;
  line-height: 21px;
  font-family: var(--typo-sansserif-fontfamily);
  font-weight: var(--typo-light-fontweight);
  padding: 15px 10px;
  width: 100%;
  border: 1px solid var(--color-main-500);
  background-color: var(--color-main-500);

  .tippy-arrow::before {
    content: '';
    border-width: 1px !important;

    border-bottom: 1px solid var(--color-main-500);
    border-right: 1px solid var(--color-main-500);
    border-left: transparent;
    border-top: transparent;
    position: absolute;
    width: 12px;
    height: 12px;
  }

  &[data-theme~='black'] {
    background-color: var(--color-main-500);

    .tippy-arrow::before {
      background-color: var(--color-main-500);
    }
  }

  &[data-theme~='white'] {
    background-color: var(--color-main-0);
    color: var(--color-main-500);

    .tippy-arrow::before {
      background-color: var(--color-main-0);
    }
  }

  ////////////////////////////////////////////////
  // Arrow positioning for different placements //
  ////////////////////////////////////////////////

  &[data-placement='bottom'],
  &[data-placement='bottom-start'],
  &[data-placement='bottom-end'] {
    .tippy-arrow::before {
      left: 6px;
      top: -17px;
      transform: rotate(-135deg) translateY(-0.5px);
    }
  }

  &[data-placement='top'],
  &[data-placement='top-start'],
  &[data-placement='top-end'] {
    .tippy-arrow::before {
      left: 6px;
      top: 12px;
      transform: rotate(45deg) translateY(0.5px);
    }
  }

  &[data-placement='left'],
  &[data-placement='left-start'],
  &[data-placement='left-end'] {
    .tippy-arrow::before {
      left: 12px;
      top: 6px;
      transform: rotate(-45deg) translateY(0.5px);
    }
  }

  &[data-placement='right'],
  &[data-placement='right-start'],
  &[data-placement='right-end'] {
    .tippy-arrow::before {
      left: -17px;
      top: 6px;
      transform: rotate(135deg) translateY(0.5px);
    }
  }
}
