.cardList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.card {
  border-radius: 10px;
  border: 1px solid var(--main-100, #b9b9b9);
  padding: 20px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &.selected {
    border-color: var(--accent-800, #9a63c3);
    background: var(--container-accent, #F6F0F9);
  }
}

.top {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.alignLeft {
  text-align: left;
}

.floatRight {
  float: right;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.gap {
  display: flex;
  gap: 5px;
  align-items: baseline;
}

.strikethrough {
  color: var(--main-200, #8a8a8a);
  text-decoration-line: line-through;
}

.labelTag {
  float: right;
}
