
.bankImage {
  width: 33px;
  position: relative;
  top: 2px;
}

.creditCardImage {
  position: relative;
  width: 35px;
  top: 0;
}