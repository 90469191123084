@import 'styles/mixins';

@import '@collegebacker/backer-ui/ui/ui.css';
@import '@collegebacker/backer-ui/ui/base.css';

/**
* ====================================================
* NORMALIZE
* ====================================================
*/

* {
  box-sizing: border-box;
}

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  color: $almost-black;
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button {
  border: none;
  background: transparent;
  font-family: var(--typo-sansserif-fontfamily);
  font-weight: var(--typo-light-fontweight);
  cursor: pointer;
  &:disabled {
    cursor: unset;
    opacity: 0.5;
  }
}

// @TODO CLEAN UP - review and/or remove .user-is-tabbing styles
body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus {
  outline: none;
}

body.user-is-tabbing button:focus,
body.user-is-tabbing span[role='radio']:focus,
body.user-is-tabbing div[role='button']:focus {
  outline: 2px solid $light-grey-blue;
  outline: 3px auto $light-grey-blue;
}

body.user-is-tabbing select:focus,
body.user-is-tabbing textarea:focus,
body.user-is-tabbing .input-radio__input:focus + .input-radio__pseudo-input,
body.user-is-tabbing .input-select-small__control--is-focused {
  outline: none;
  box-shadow: 0 0 2pt 1pt $light-grey-blue;
}

.input-shareable-url__input:focus {
  box-shadow: none !important;
  outline: none;
}

/* Remove input background color for Chrome autocomplete  */
input:-webkit-autofill,
select:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 5000s;
}

input,
textarea,
button,
select,
div,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

input {
  background-color: transparent;
  border: none;
  font: inherit;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  @include placeholder;
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  @include placeholder;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  @include placeholder;
}

// temporary fix for Google Chrome bug (version 87.0.4280.67) rendering fieldset with grey box
fieldset {
  border: 1px solid transparent !important;
}

ul {
  list-style-type: none;
}

li {
  display: block;
  margin: 0;
  padding: 0;
}

/**
* ====================================================
* LAYOUT
* ====================================================
*/

html {
  -webkit-overflow-scrolling: touch;
  height: -webkit-fill-available;
}

body {
  overflow-x: hidden;
  width: 100%;
  min-height: 100vh;
  // mobile viewport bug fix
  min-height: -webkit-fill-available;
  color: var(--color-main-500);
  background: var(--color-main-0);
  margin: 0;
  padding: 0;
}
h1,
h2,
h3 {
  margin: 0 0 20px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
h1 {
  font-size: 32px;
  line-height: 40px;
}
h2 {
  font-size: 24px;
  line-height: 32px;
}
h3 {
  font-size: 20px;
  line-height: 28px;
}
p {
  font-size: 17px;
}
a {
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  color: inherit;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.canvas {
  margin: 0 auto;
  max-width: 660px;
}
.flex-grid {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .col {
    flex: 1;
  }
}
.flex-grid--centered {
  justify-content: center;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content--max-width {
  max-width: $content-max-width;
  margin: 0 auto;
}

/**
* ====================================================
* UTILITIES
* ====================================================
*/

.mt-64 {
  margin-top: 64px;
}
.mt-32 {
  margin-top: 32px;
}
.mb-8 {
  margin-bottom: 8px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-32 {
  margin-bottom: 32px;
}
.mb-40 {
  margin-bottom: 40px;
}
.center-hor {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.hide-mobile-xs,
.hide-mobile-s,
.hide-mobile-m,
.show-hi-res {
  display: none;
}

.capitalize-first-letter {
  display: inline-block;
  &:first-letter {
    text-transform: uppercase;
  }
}

.lowercase {
  text-transform: lowercase;
}

/**
 * ====================================================
 * SKELETON
 * ====================================================
 */

.active-skeleton-container {
  pointer-events: none;
  .hide-skeleton {
    display: none;
  }
  .skeleton-animation {
    background: #f6f6f6;
    color: rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
    border: 0;
    border-radius: 5px;
    &--dark {
      background: #d8d8d8;
    }
    &::after {
      display: block;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.4),
        transparent
      );
      animation: loading 2s infinite;
    }
    @keyframes loading {
      100% {
        transform: translateX(100%);
      }
    }
    @-moz-keyframes loading {
      100% {
        transform: translateX(100%);
      }
    }
    @-webkit-keyframes loading {
      100% {
        transform: translateX(100%);
      }
    }
    @-o-keyframes loading {
      100% {
        transform: translateX(100%);
      }
    }
  }
}
.testing-skeleton {
  .active-skeleton-container {
    pointer-events: inherit;
  }
}

/**
* ====================================================
* MISC
* ====================================================
*/

.dollar-sign {
  font-size: 0.5em;
  vertical-align: super;
}

.back-arrow-icon {
  width: 24px;
  height: 24px;
}
.secondary-text {
  font-size: 14px;
  line-height: 20px;
  color: #7b7b7b;
}

.header-emoji {
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 15px;
}

@media only screen and (min-width: $device-xs) {
  .hide-mobile-xs {
    display: inline-flex;
  }
  .show-desktop-xs {
    display: none;
  }
}

@media only screen and (min-width: $device-s) {
  .hide-mobile-s {
    display: inline-flex;
  }
  .hide-desktop-s {
    display: none;
  }
}

@media only screen and (min-width: $device-m) {
  .hide-m {
    display: none;
  }
  .hide-mobile-m {
    display: inline-block;
  }
}
@media only screen and (min-width: $device-hi-res) {
  .show-hi-res {
    display: inline-block;
  }
}
